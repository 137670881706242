import { useContext } from "react";
import PageLoadContext from "../models/PageLoadContext";

export const usePageLoadContext = () => {
  const context = useContext(PageLoadContext);
  if (!context) {
    throw new Error("useLoading must be used within a LoadingProvider");
  }
  return context;
};
