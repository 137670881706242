import { CLEAR_QUIZ_ERRORS } from "../../../constants/actions";
import { ErrorComponentProps } from "../../../models/caughtError";
import { FullWidthButton } from "../../../components/styled/button";
import project from "../../../project";
import styled from "styled-components";
import { useAppDispatch } from "../../../store";

const QuizErrorOuter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  margin-top: 40px;
  width: 40%;
  @media (max-width: 768px) {
    margin: 0 ${(props) => props.theme.spacing.md};
    margin-top: 40px;
    width: auto;
  }
`;

const ErrorFound = styled.div`
  border: 2px solid red;
  margin-top: 30px;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffe5e3;
`;

const LogoImage = styled.img`
  width: auto;
  height: 30px;
  z-index: 1;
  margin: auto;
  @media (max-width: 768px) {
    width: 30%;
  }
`;

const ErrorText = styled.div`
  background-color: #ffffff;
  padding: 5px;
`;

const FullWidthButtonHere = styled(FullWidthButton)`
  margin-top: 40px;
`;

export const QuizErrorHandler = ({ errors }: ErrorComponentProps) => {
  const dispatch = useAppDispatch();
  if (!errors?.length) {
    return;
  }
  async function copyTextToClipboard(text: string) {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  }
  function clearErrorAndProceed() {
    dispatch({
      type: CLEAR_QUIZ_ERRORS,
    });
    // check quiz step and move to next quiz page?
  }
  return (
    <QuizErrorOuter>
      <LogoImage
        alt={"Inflow logo"}
        src={`${project.s3AssetsBaseUrl}/inflow-logo.svg`}
      />
      <ErrorFound>
        <div>Well, this is not what we were hoping to show you here.</div>
        <div>
          An error has occured, and we've notified the team so they can get it
          fixed.
        </div>
        <div>Our apologies for disrupting your experience.</div>
        {errors?.map((error: any, index: number) => {
          const copyErrorText = `Page: ${error.page}\n${error.message}`;
          return (
            <ErrorText
              key={index}
              onClick={() => copyTextToClipboard(copyErrorText)}
            >
              <em>Error (tap box to copy):</em>
              <br />
              Page: {error.page}
              <br />
              Error: {error.message}
            </ErrorText>
          );
        })}
      </ErrorFound>
      <FullWidthButtonHere
        onClick={() => {
          clearErrorAndProceed();
        }}
      >
        OK, let's carry on
      </FullWidthButtonHere>
    </QuizErrorOuter>
  );
};
