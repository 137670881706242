import { AppAction, CLEAR_QUIZ_ERRORS, QUIZ_ERROR } from "../constants/actions";

export type UserEmailSource =
  | "QUIZ"
  | "QUERY"
  | "STORED_GO_BACK"
  | "STRIPE_REDIRECT";

type QuizErrorState = Partial<{
  errors: any;
}>;

const initialState: QuizErrorState = {
  errors: [],
};

export default (
  state: QuizErrorState = initialState,
  action: AppAction,
): QuizErrorState => {
  switch (action.type) {
    case QUIZ_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.error],
      };
    }
    case CLEAR_QUIZ_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }
    default: {
      return state;
    }
  }
};
