import { RootState } from "../reducers";
import { UserEmailSource } from "../reducers/user.ts";

export function selectUserToken(state: RootState): string | null {
  return state.user.token;
}

export function selectUserEmail(state: RootState): string | undefined {
  return state.user.email;
}

export function selectUserPhoneNumber(state: RootState): string | undefined {
  return state.user.phoneNumber;
}

export function selectUserEmailSource(
  state: RootState,
): UserEmailSource | undefined {
  return state.user.emailSource;
}

export function selectUserFirstName(state: RootState): string | undefined {
  return state.user.firstName;
}

export function selectUserLasttName(state: RootState): string | undefined {
  return state.user.lastName;
}

export function selectUserUsState(state: RootState): string | undefined {
  return state.user.usState;
}

export function selectUserClinicFunnelOrigin(
  state: RootState,
): string | undefined {
  return state.user.clinicFunnelOrigin;
}

export function selectUserFullName(state: RootState):
  | {
      firstName?: string | undefined;
      lastName?: string | undefined;
    }
  | undefined {
  return {
    firstName: state.user.firstName,
    lastName: state.user.lastName,
  };
}
