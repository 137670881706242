import moment from "moment";
import project from "../project";

const disableAnalytics =
  project.customEnv === "development" ||
  window.localStorage.getItem("e2eTestUser");

export function cioUpdateUserAttributes(uuid: any, attributes: any) {
  (window as any).cio.identify({
    id: uuid,
    ...attributes,
  });
}

export function cioIdentify(uuid: any) {
  if (disableAnalytics) {
    return;
  }

  (window as any).cio.identify({
    id: uuid,
    "Distinct ID": uuid,
    Time: moment().unix().toString(),
  });
}

export function cioIdentifyWithEmail(uuid: string, email: string) {
  if (disableAnalytics) {
    return;
  }

  (window as any).cio.identify({
    id: uuid,
    email,
  });
}

export function cioIdentifyRegisteredUser(uuid: string, email: string) {
  if (disableAnalytics) {
    return;
  }

  (window as any).cio.identify({
    id: uuid,
    email,
    registeredOnWeb: true,
  });
}

export function cioTrackAnalytics(eventName: string, eventProps: any) {
  if (disableAnalytics) {
    return;
  }
  const propsWithDeviceInfo = {
    ...eventProps,
  };
  (window as any).cio.track(eventName, propsWithDeviceInfo);
}
