import { UseQueryResult, useQuery } from "@tanstack/react-query";

import { USER_EMAIL_CHANGED } from "../constants/actions";
import http from "../utils/http";
import { selectUserToken } from "../selectors/user";
import { useAppDispatch } from "../store";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function useUserData(
  hydrateStore?: boolean,
): UseQueryResult<UserData> & {
  isLoggedIn: boolean;
  userData: UserData | undefined;
} {
  const token = useSelector(selectUserToken);

  const result = useQuery<UserData>({
    queryKey: ["user-data"],
    queryFn: () =>
      http(token)
        .get("user")
        .then((res) => res.data as UserData),
    enabled: !!token,
    retry: 3,
  });

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (result.data && hydrateStore) {
      // hydrate the store with the user data
      dispatch({
        type: USER_EMAIL_CHANGED,
        email: result.data.email,
      });
    }
  }, [dispatch, hydrateStore, result.data]);

  return {
    isLoggedIn: result.data != null,
    userData: result.data,
    ...result,
  };
}

export type UserData = {
  email: string;
  firstName: string;
  lastName: string;
  timeZone?: string;
  username: string;
  uuid?: string;
  hasMemberEntitlement?: boolean;
  canAccessMedManagement?: boolean;
  medManagementAppointmentTypeId?: string;
  medManagementFollowupAppointmentTypeId?: string;
  medManagementHealthieId?: string;
  medManagementHealthieConversationId?: string;
  intakeAssessmentStatus?: string;
};
