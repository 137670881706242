import { useEffect } from "react";
import { useSearchParams } from "../custom-hooks/useSearchParams";
import { useDispatch, useSelector } from "react-redux";
import utils from "../utils";
import useRemoteConfig from "../custom-hooks/useRemoteConfig";
import useUserData from "../custom-hooks/useUserData";
import { selectUserToken } from "../selectors/user";

// const DEEPLINK_URL = "inflowadhd://";

const DeepLinking = () => {
  const dispatch = useDispatch();
  const searchParams = useSearchParams();
  const screen = searchParams.get("screen");

  const token = useSelector(selectUserToken);

  const { userData } = useUserData();

  const { generalSettings } = useRemoteConfig();
  const medManagementUrl = generalSettings?.MED_MANAGEMENT_URL ?? "";

  // const launchApp = (
  //   deepLink: string,
  //   fallBack: string,
  //   { data }: UserState
  // ) => {
  //   var now = new Date().valueOf();
  //   setTimeout(function () {
  //     if (new Date().valueOf() - now > 100) return;
  //     window.location.href = fallBack;
  //   }, 25);
  //   if (data?.hasMemberEntitlement) window.location.href = deepLink;
  // };

  useEffect(() => {
    if (token && !window.localStorage.getItem("token")) {
      window.localStorage.setItem("token", utils.decodeToken(token));
    }
  }, [token, dispatch]);

  useEffect(() => {
    // let deeplink: string;
    const urlParams = new URLSearchParams({
      token: token || "",
      screen: screen || "",
      external_flow_source: "Web",
    });
    const fallBack = `${medManagementUrl}&${urlParams.toString()}`;
    // const fallBack = `http://localhost:3000/go?to=https%3A%2F%2Flets.getinflow.io%2Fclinic-only-lp-1%2F&event=External%20User%20Flow%20Started&product_name=Med%20Management&external_flow_source=App&${urlParams.toString()}`;
    if (userData && token) {
      switch (screen) {
        case "Clinic":
          window.location.href = fallBack;
          // deeplink = `${DEEPLINK_URL}?${urlParams.toString()}`;
          // launchApp(deeplink, fallBack, user);
          break;
        default:
          break;
      }
    }
  }, [screen, token, medManagementUrl, userData]);

  return <></>;
};

export default DeepLinking;
