import { UseQueryResult, useQuery } from "@tanstack/react-query";

import axios from "axios";
import useRemoteConfig from "./useRemoteConfig";

export type GeoLocation = {
  city: string;
  country: string;
  countryCode: string;
  region: string;
};

export interface GeoData {
  ip?: string;
  location?: GeoLocation;
  meta?: {
    isEu: boolean;
    isGdpr: boolean;
    currency: string;
  };
}

export default function useFetchGeoLocation(): UseQueryResult<GeoData, Error> {
  const remoteConfig = useRemoteConfig();
  const requestIP = remoteConfig?.data?.requestIP;
  const geoServiceLocation =
    remoteConfig?.data?.environmentVariables?.AWS_GEO_SERVICE_LOCATION;

  return useQuery<GeoData>({
    staleTime: 1000 * 60 * 60 * 24,
    queryKey: ["geo-data", requestIP],
    queryFn: () =>
      axios
        .get(geoServiceLocation + `?ip=${requestIP}`)
        .then((res) => res.data),
    retry: 3,
    enabled: geoServiceLocation != null && requestIP != null,
  });
}
