export default (str: string): boolean => {
  try {
    const tokencheck = btoa(atob(str)) === str;
    console.log("tc", tokencheck);
    return tokencheck;
  } catch (err) {
    console.log("tc-error", err);
    return false;
  }
};
