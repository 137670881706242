import {
  Navigate,
  Outlet,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import React, { Suspense } from "react";

import DeepLinking from "../pages/DeepLinking";
import Dispatcher from "../pages/Dispatcher";
import DispatcherGenerator from "../pages/DispatcherGenerator";
import LoadingRadial from "../components/base/LoadingRadial";
import NavBar from "../components/NavBar";
import RequireAnon from "../RequireAnon";
import RequireAuth from "../RequireAuth";

const MedStatus = React.lazy(() => import("../pages/MedManagement/MedStatus"));
const Landing = React.lazy(() => import("../pages/Landing"));
const Login = React.lazy(() => import("../pages/Login"));

const MyResultsPage = React.lazy(
  () => import("../adhdQuiz/pages/MyResultsPage"),
);
const CheckoutPaymentPage = React.lazy(
  () => import("../checkout/pages/CheckoutPaymentPage"),
);

const CheckoutPriceOptionsPage = React.lazy(
  () => import("../checkout/pages/CheckoutPriceOptionsPage"),
);

const AssessmentIntake = React.lazy(
  () => import("../pages/MedManagement/AssessmentIntake"),
);

const AssessmentStatus = React.lazy(
  () => import("../pages/MedManagement/AssessmentStatus"),
);

const CancelBooking = React.lazy(
  () => import("../pages/MedManagement/CancelBooking"),
);

const CancellationSurvey = React.lazy(
  () => import("../pages/MedManagement/CancellationSurvey"),
);

const ClinicRegistrationScreen = React.lazy(
  () => import("../pages/MedManagement/registration/ClinicRegistrationScreen"),
);

const AccountRouter = React.lazy(
  () => import("../pages/Account/AccountRouter"),
);

const AdhdQuizBaseRouter = React.lazy(
  () => import("../adhdQuiz/routing/AdhdQuizBaseRouter"),
);

const IntakeForm = React.lazy(() => import("../pages/Coaching/IntakeForm"));

const MedIntake = React.lazy(() => import("../pages/MedManagement/MedIntake"));

const MedManagementRejection = React.lazy(
  () => import("../pages/MedManagement/MedManagementRejection"),
);

const MedManagementRouter = React.lazy(() => import("./MedManagementRouter"));

const PostPurchase = React.lazy(() => import("../pages/PostPurchase"));

const ResetPassword = React.lazy(() => import("../pages/ResetPassword"));

const WebViewQuizRouter = React.lazy(() => import("./WebViewQuizRouter"));

export function AppRouter() {
  return createBrowserRouter(
    createRoutesFromElements(
      <Route
        path={"/"}
        element={
          <Suspense fallback={<LoadingRadial />}>
            <NavBar />
            <Outlet />
          </Suspense>
        }
      >
        <Route
          path={"/"}
          element={
            <Suspense fallback={<LoadingRadial />}>
              <Landing />
            </Suspense>
          }
        />
        <Route
          path="/login"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <Login />
            </Suspense>
          }
        />

        <Route
          path="/checkout/price-options"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <CheckoutPriceOptionsPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path="/checkout/payment/:priceId"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <CheckoutPaymentPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path="/checkout/start-trial"
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <CheckoutPaymentPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path={"/adhd-quiz/*"}
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial state="quiz-router-load" />}>
                <AdhdQuizBaseRouter />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path={"/results/*"}
          element={
            <RequireAnon>
              <Suspense fallback={<LoadingRadial />}>
                <MyResultsPage />
              </Suspense>
            </RequireAnon>
          }
        />

        <Route
          path={"/post-purchase"}
          element={
            <Suspense fallback={<LoadingRadial />}>
              <PostPurchase />
            </Suspense>
          }
        />

        <Route
          path="/account/*"
          element={
            <RequireAuth to="/login">
              <Suspense fallback={<LoadingRadial />}>
                <AccountRouter />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="/clinic-registration"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <ClinicRegistrationScreen />
            </Suspense>
          }
        />

        <Route
          path="/reset-password"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          path="/go/generator"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <DispatcherGenerator />
            </Suspense>
          }
        />
        <Route
          path="/go"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <Dispatcher />
            </Suspense>
          }
        />
        <Route
          path="/deep-link"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <DeepLinking />
            </Suspense>
          }
        />
        <Route
          path="/clinic/*"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <MedManagementRouter />
            </Suspense>
          }
        />

        <Route
          path="/coach-intake"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <IntakeForm />
            </Suspense>
          }
        />

        <Route
          path="/med-intake"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <MedIntake />
            </Suspense>
          }
        />

        <Route
          path="/assessment-intake"
          element={
            <RequireAuth to="/login" path="/assessment-intake">
              <Suspense fallback={<LoadingRadial />}>
                <AssessmentIntake />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="/cancellation-survey"
          element={
            <RequireAuth to="/login" path="/cancellation-survey">
              <Suspense fallback={<LoadingRadial />}>
                <CancellationSurvey />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="/med-status"
          element={
            <RequireAuth path="/med-status" to="/clinic-registration">
              <Suspense fallback={<LoadingRadial />}>
                <MedStatus />
              </Suspense>
            </RequireAuth>
          }
        />

        <Route
          path="/cancel-booking"
          element={
            <RequireAuth>
              <Suspense fallback={<LoadingRadial />}>
                <CancelBooking />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/med-status"
          element={
            <RequireAuth to="/clinic-registration" path="/med-status">
              <Suspense fallback={<LoadingRadial />}>
                <MedStatus />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/assessment-status"
          element={
            <RequireAuth path="/assessment-status">
              <Suspense fallback={<LoadingRadial />}>
                <AssessmentStatus />
              </Suspense>
            </RequireAuth>
          }
        />
        <Route
          path="/med-qualify"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <MedManagementRejection />
            </Suspense>
          }
        />
        <Route
          path="/web-view-quiz/*"
          element={
            <Suspense fallback={<LoadingRadial />}>
              <WebViewQuizRouter />
            </Suspense>
          }
        />
        <Route path="*" element={<Navigate to={"/"} replace />} />
      </Route>,
    ),
  );
}
