import { Navigate, useLocation } from "react-router-dom";

import LoadingRadial from "./components/base/LoadingRadial";
import { ReactNode } from "react";
import useUserData from "./custom-hooks/useUserData";

function RequireAuth({
  children,
  path,
  to,
}: {
  children: ReactNode;
  path?: string;
  to?: string;
}) {
  const location = useLocation();
  const params = new URLSearchParams({
    ...Object.fromEntries(new URLSearchParams(location.search)),
    ...(path ? { destination: path } : {}),
  });

  const { isLoading: userDataLoading, isLoggedIn } = useUserData();
  if (isLoggedIn) return children;
  if (userDataLoading) return <LoadingRadial />;
  return (
    <Navigate replace to={to ? `${to}?${params.toString() || ""}` : "/"} />
  );
}

export default RequireAuth;
