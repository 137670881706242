import { AppAction } from "../constants/actions.ts";
import { Dispatch } from "react";
import analytics from "./analytics";
import checkout from "./checkout";
import { combineReducers } from "redux";
import coupon from "./coupon";
import errors from "./errors.ts";
import user from "./user";

const rootReducer = combineReducers({
  user,
  coupon,
  analytics,
  checkout,
  errors,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = Dispatch<AppAction>;

export default rootReducer;
