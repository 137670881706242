import { trackAnalyticsServer } from "./analytics";

// logs the quiz error to Mixpanel
export function logQuizError(error: any, analyticsUuid: string) {
  trackAnalyticsServer(
    analyticsUuid,
    "QuizError",
    {
      errorPage: error.page,
      errorMessage: error.message,
    },
    undefined,
  );
}
