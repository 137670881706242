import { FormEvent, useState } from "react";

export const eventsData = {
  EXTERNAL_USER_FLOW_STARTED: "External User Flow Started",
  CLINIC_WEBFLOW_CTA_CLICKED: "Clinic Webflow CTA Clicked",
  EXTERNAL_LANDING_PAGE_VIEWED: "External Landing Page Viewed",
  INTAKE_FORM_COMPLETED: "Intake Form Completed",
  COACH_SELECTED: "Coach Selected",
  APPOINTMENT_BOOKED: "Appointment Booked",
  FOLLOW_UP_APPOINTMENT_CLICKED: "Follwup Appointment Link Clicked",
};

const sourcesData = ["", "App", "Email", "Google Ads"];

const DispatcherGenerator = () => {
  const productNameData = ["", "Coaching", "Med Management"];
  const webflowClickTypeData = ["", "Savvy", "Typeform", "Webflow"];

  const [event, setEvent] = useState(eventsData.EXTERNAL_USER_FLOW_STARTED);
  const [product, setProduct] = useState(productNameData[0]);
  const [webflowClickType, setWebflowClickType] = useState(
    webflowClickTypeData[0],
  );
  const [redirectLink, setRedirectLink] = useState("");
  const [campaignName, setCampaignName] = useState("");
  // const [appointmentName, setAppointmentName] = useState("");
  const [appointmentExpert, setAppointmentExpert] = useState("");
  const [source, setSource] = useState("");
  const [userIdTemplate, setUserIdTemplate] = useState("");
  // const [userId, setUserId] = useState("");
  const [testing, setTesting] = useState("");
  // const [backlink, setBacklink] = useState("");
  const [error, setError] = useState("");
  const [link, setLink] = useState("");
  const [linkType, setLinkType] = useState("");
  const [environment, setEnvironment] = useState(
    "https://www.join.getinflow.io/go?",
  );

  const generateLink = (type: string, e: FormEvent) => {
    setLink("");
    setError("");
    setLinkType("");
    e.preventDefault();
    if (!event || (!redirectLink && event !== eventsData.APPOINTMENT_BOOKED)) {
      setError("Missing parameters");
      return;
    }
    // else if (userId && !isValidUUID(userId)) {
    //   setError("Invalid UUID");
    //   return;
    // }
    else if (redirectLink) {
      try {
        new URL(redirectLink);
      } catch (e) {
        setError("Invalid redirect URL");
        return;
      }
      setError("");
    }
    const params = {
      to: redirectLink ? redirectLink.trim() : "",
      event: event.trim(),
      // uuid: userId.trim(),
      test: testing.trim(),
      // back: backlink.trim(),
      product_name: product.trim(),
      external_flow_campaign: campaignName.trim(),
      coach_name: appointmentExpert.trim(),
      // appointment_name: appointmentName.trim(),
      external_flow_source: source.trim(),
      webflow_click_type: webflowClickType.trim(),
    };
    const encodedParams = Object.entries(params)
      .filter(([, value]) => value)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join("&");

    const link =
      `${environment}${encodedParams}` +
      (userIdTemplate ? "&uuid={{customer.id}}" : "");
    setLinkType(type);
    setLink(link);
  };

  let linkData = <></>;
  if (linkType === "acuity") {
    linkData = (
      // clientTime and clinetDate could be added, but it is pretty useles because timezone is not known
      <pre style={styles.linkData}>
        {`<script type="text/javascript">
window.top.location="${link}&appointment_cost=%price%&appointment_name=%appointmentType%&appointment_expert=%calendar%&email=%email%&appointment_id=%id%&back=true";
</script>`}
      </pre>
    );
  } else {
    linkData = (
      <a href={link} style={styles.linkData}>
        {link}
      </a>
    );
  }

  const events = Object.values(eventsData).map((event, i) => {
    return (
      <option key={i} value={event}>
        {event}
      </option>
    );
  });

  const sources = Object.values(sourcesData).map((event, i) => {
    return (
      <option key={i} value={event}>
        {event}
      </option>
    );
  });

  const products = productNameData.map((product, i) => {
    return (
      <option key={i} value={product}>
        {product}
      </option>
    );
  });

  const webflowClickTypes = webflowClickTypeData.map((type, i) => {
    return (
      <option key={i} value={type}>
        {type}
      </option>
    );
  });

  let appointmentFields = null;
  if (event === eventsData.COACH_SELECTED) {
    appointmentFields = (
      <div>
        {/* <div style={{ marginTop: 10 }}>
          <label style={styles.label}>Appointment Name:</label>
          <input
            style={styles.input}
            type="text"
            id="appointmentName"
            value={appointmentName}
            onChange={(e) => setAppointmentName(e.target.value)}
          />
        </div> */}
        <div style={{ marginTop: 10 }}>
          <label style={styles.label}>Coach Name:</label>
          <input
            style={styles.input}
            type="text"
            id="appointmentExpert"
            value={appointmentExpert}
            onChange={(e) => setAppointmentExpert(e.target.value)}
          />
        </div>
      </div>
    );
  }

  let sourceProperty;
  if (
    [
      eventsData.EXTERNAL_USER_FLOW_STARTED,
      eventsData.FOLLOW_UP_APPOINTMENT_CLICKED,
    ].includes(event)
  ) {
    sourceProperty = (
      <div>
        <label style={styles.label} htmlFor="event">
          Source:
        </label>
        <select
          id="source"
          value={source}
          style={styles.select}
          onChange={(e) => setSource(e.target.value)}
        >
          {sources}
        </select>
      </div>
    );
  }

  let productProperty;
  if (
    [
      eventsData.EXTERNAL_USER_FLOW_STARTED,
      eventsData.FOLLOW_UP_APPOINTMENT_CLICKED,
      eventsData.EXTERNAL_LANDING_PAGE_VIEWED,
      eventsData.CLINIC_WEBFLOW_CTA_CLICKED,
    ].includes(event)
  ) {
    productProperty = (
      <div>
        <label style={styles.label} htmlFor="product">
          Product:
        </label>
        <select
          id="product"
          value={product}
          style={styles.select}
          onChange={(e) => setProduct(e.target.value)}
        >
          {products}
        </select>
      </div>
    );
  }

  let webflowClickTypeProperty;
  if ([eventsData.CLINIC_WEBFLOW_CTA_CLICKED].includes(event)) {
    webflowClickTypeProperty = (
      <div>
        <label style={styles.label} htmlFor="webflowClickType">
          Webflow Click Type:
        </label>
        <select
          id="webflowClickType"
          value={webflowClickType}
          style={styles.select}
          onChange={(e) => setWebflowClickType(e.target.value)}
        >
          {webflowClickTypes}
        </select>
      </div>
    );
  }

  let redirectLinkField;
  if (event != eventsData.APPOINTMENT_BOOKED) {
    redirectLinkField = (
      <div style={{ marginTop: 10 }}>
        <label style={styles.label} htmlFor="redirectLink">
          Redirect Link:
        </label>
        <input
          style={styles.input}
          type="text"
          id="redirectLink"
          value={redirectLink}
          onChange={(e) => setRedirectLink(e.target.value)}
        />
      </div>
    );
  }

  let campaignNameField;
  if (
    [eventsData.EXTERNAL_USER_FLOW_STARTED].includes(event) &&
    source === "Email"
  ) {
    campaignNameField = (
      <div style={{ marginTop: 10 }}>
        <label style={styles.label} htmlFor="campaignName">
          Campaign Name:
        </label>
        <input
          style={styles.input}
          type="text"
          id="campaignName"
          value={campaignName}
          onChange={(e) => setCampaignName(e.target.value)}
        />
      </div>
    );
  }

  let customerIdTemplate;
  if (
    [eventsData.EXTERNAL_USER_FLOW_STARTED].includes(event) &&
    source === "Email"
  ) {
    customerIdTemplate = (
      <div style={{ marginTop: 10 }}>
        <label htmlFor="test">Use customer.io user id template: </label>
        <input
          style={{ borderWidth: 1 }}
          type="checkbox"
          id="test"
          onChange={(e) => setUserIdTemplate(e.target.checked ? "true" : "")}
        />
      </div>
    );
  }

  return (
    <form style={{ padding: 20 }}>
      <div>
        <label style={styles.label} htmlFor="environment">
          Environment:
        </label>
        <select
          id="environment"
          value={environment}
          style={styles.select}
          onChange={(e) => setEnvironment(e.target.value)}
        >
          <option value="https://www.join.getinflow.io/go?">Production</option>
          <option value="https://staging.d3h18sicxdfo8a.amplifyapp.com/go?">
            Staging
          </option>
          <option value="http://localhost:3000/go?">Local</option>
        </select>
      </div>
      <div>
        <label style={styles.label} htmlFor="event">
          Event:
        </label>
        <select
          id="event"
          value={event}
          style={styles.select}
          onChange={(e) => {
            setEvent(e.target.value);
            setAppointmentExpert("");
            setCampaignName("");
            setLink("");
            setProduct("");
            setRedirectLink("");
            setUserIdTemplate("");
            setSource("");
          }}
        >
          {events}
        </select>
      </div>
      {sourceProperty}
      {productProperty}
      {webflowClickTypeProperty}

      {appointmentFields}
      {redirectLinkField}
      {campaignNameField}
      {customerIdTemplate}
      {/* <div style={{ marginTop: 10 }}>
        <label style={styles.label} htmlFor="userId">
          User ID:
        </label>
        <input
          style={userIdTemplate ? styles.disabledInput : styles.input}
          type="text"
          disabled={!!backlink}
          id="userId"
          value={userId}
          onChange={(e) => setUserId(e.target.value)}
        />
      </div> */}
      {/* <div style={{ marginTop: 10 }}>
        <label htmlFor="test">Go back after redirect: </label>
        <input
          style={{ borderWidth: 1 }}
          type="checkbox"
          id="test"
          onChange={(e) => setBacklink(e.target.checked ? "true" : "")}
        />
      </div> */}
      <div style={{ marginTop: 10 }}>
        <label htmlFor="test">Test: </label>
        <input
          style={{ borderWidth: 1 }}
          type="checkbox"
          id="test"
          onChange={(e) => setTesting(e.target.checked ? "true" : "")}
        />
      </div>
      <button
        type="submit"
        style={styles.button}
        onClick={generateLink.bind(
          this,
          event === eventsData.APPOINTMENT_BOOKED ? "acuity" : "link",
        )}
      >
        Generate link
      </button>
      <div style={{ color: "red" }}>{error}</div>
      {linkData}
    </form>
  );
};

const styles = {
  label: {
    width: 300,
    display: "block",
  },
  input: {
    padding: 5,
    borderWidth: 1,
    width: 300,
  },
  disabledInput: {
    padding: 5,
    borderWidth: 1,
    width: 300,
    backgroundColor: "#ddd",
  },
  select: {
    borderWidth: 1,
    borderColor: "#eee",
    padding: 5,
    width: 300,
  },
  button: {
    padding: 10,
    marginTop: 10,
    color: "white",
    backgroundColor: "green",
    cursor: "pointer",
    marginRight: 20,
  },
  linkData: {
    backgroundColor: "#eee",
    marginTop: 20,
    padding: 10,
    display: "block",
  },
};

export default DispatcherGenerator;
