import { ReactElement, ReactNode, useEffect, useReducer } from "react";
import {
  getPersistedQuizState,
  persistQuizState,
} from "../utils/persistedQuizState";

import QuizStateContext from "../models/QuizStateContext";
import { quizReducer } from "../models/QuizState";

type QuizStateProviderProps = {
  children: ReactNode;
};

const QuizStateProvider = ({
  children,
}: QuizStateProviderProps): ReactElement => {
  const [quizState, dispatch] = useReducer(
    quizReducer,
    getPersistedQuizState(),
  );

  useEffect(() => {
    // Update the persisted quiz state with the new quiz state
    persistQuizState(quizState);
  }, [quizState]);

  return (
    <QuizStateContext.Provider
      value={{
        quizState,
        dispatch,
      }}
    >
      {children}
    </QuizStateContext.Provider>
  );
};

export default QuizStateProvider;
