import { createContext } from "react";

interface PageLoadContextProps {
  startTiming: (state: string, isInitial?: boolean) => void;
  stopTiming: (state: string) => void;
  sendEvent: () => void;
}

const PageLoadContext = createContext<PageLoadContextProps | undefined>(
  undefined,
);

export default PageLoadContext;
