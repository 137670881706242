import Axios from "axios";
import { persistor } from "../store";
import project from "../project";
import utils from ".";
const { apiBase, headerKey } = project;

export default (storeToken?: string | null) => {
  const token = storeToken || utils.getToken();
  const axios = Axios.create({
    baseURL: apiBase,
    headers: {
      Authorization: `Bearer ${token}`,
      "api-key": headerKey,
    },
  });

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        window.localStorage.setItem("token", "");
        window.localStorage.setItem("anonymousUuid", "");
        await persistor.purge();
        window.location.href = "/";
      }
      if (error.response.status === 409) {
        return Promise.reject(error.response.data);
      }
      return Promise.reject(error);
    },
  );

  return axios;
};
