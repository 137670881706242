import { Dispatch, createContext } from "react";
import { QuizAction, QuizState } from "./QuizState";

export type QuizStateContextT = {
  quizState: QuizState;
  dispatch: Dispatch<QuizAction>;
};

const QuizStateContext = createContext<QuizStateContextT | null>(null);

export default QuizStateContext;
