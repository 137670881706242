import {
  AppAction,
  COUPON_API_RESULTS,
  COUPON_CODE_FAILURE,
  COUPON_CODE_LOADING,
  COUPON_CODE_RESET,
  COUPON_CODE_SUCCESS,
} from "../constants/actions";

import { produce } from "immer";

interface CouponState {
  loading: boolean;
  percentOff: number;
  couponCode: string | undefined;
  couponApplied: boolean;
  apiResults: any;
}

const initialState: CouponState = {
  loading: false,
  percentOff: 0,
  couponCode: undefined,
  couponApplied: false,
  apiResults: undefined,
};

export default (
  state: CouponState = initialState,
  action: AppAction,
): CouponState => {
  return produce(state, (draft) => {
    switch (action.type) {
      case COUPON_CODE_LOADING: {
        draft.loading = true;
        break;
      }
      case COUPON_CODE_SUCCESS: {
        draft.loading = false;
        draft.percentOff = action.percentOff;
        draft.couponCode = action.couponCode;
        draft.couponApplied = true;
        break;
      }
      case COUPON_CODE_RESET:
      case COUPON_CODE_FAILURE: {
        draft.loading = false;
        draft.percentOff = 0;
        draft.couponCode = undefined;
        draft.couponApplied = false;
        break;
      }
      case COUPON_API_RESULTS: {
        draft.apiResults = action.data;
      }
    }
  });
};
