import LoadingRadial from "./components/base/LoadingRadial";
import { Navigate } from "react-router-dom";
import { ReactNode } from "react";
import useUserData from "./custom-hooks/useUserData";

function RequireAnon({ children, to }: { children: ReactNode; to?: string }) {
  const { isLoading: userDataLoading, isLoggedIn } = useUserData();
  if (userDataLoading) return <LoadingRadial />;
  if (!isLoggedIn) return children;
  return <Navigate replace to={to ?? "/account"} />;
}

export default RequireAnon;
