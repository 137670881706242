import { GTag, getAdMetrics, identify } from "../utils/analytics.ts";

import { checkUuidVal } from "../reducers/analytics.ts";
import { cioIdentify } from "../services/CustomerIO.ts";
import { logErr } from "../utils/logs.ts";
import project from "../project.ts";
import { selectAnalyticsUuid } from "../selectors/analytics.ts";
import { updateAnalyticsUserInfo } from "../services/Api.ts";
import { useEffect } from "react";
import useFetchGeoLocation from "./useFetchGeoLocation.ts";
import { useSelector } from "react-redux";

// Identify user in our various analytics providers, including our own DB
export default function useIdentifyAnalyticsUser(
  isExperimentUser: boolean,
  swimlane?: string,
) {
  const uuid = useSelector(selectAnalyticsUuid);
  const { data: geoData, isError: geoError } = useFetchGeoLocation();
  const currencyCode = geoData?.meta?.currency;

  useEffect(() => {
    if (!geoData && !geoError) return; // wait for geo data

    checkUuidVal(uuid, "useIdentifyAnalyticsUser");
    if (!currencyCode || currencyCode.length !== 3) {
      logErr("Invalid/unknown currency code", currencyCode);
    }

    const adMetrics = getAdMetrics();

    const userInfo = {
      adMetrics,
      ...(geoData ? { location: geoData.location } : {}),
      userProperties: {
        ...(isExperimentUser ? { "experiment-user": "true" } : {}),
        ...(currencyCode ? { "currency-code": currencyCode } : {}),
        ...(swimlane ? { swimlane } : {}),
      },
    };

    // Attempt to get the GA clientID for storing in our user analytics table
    getGAClientId()
      .then((clientId) => {
        updateAnalyticsUserInfo(uuid, {
          ...userInfo,
          ...(clientId ? { googleClientId: clientId } : {}),
        });
      })
      .catch((error) => {
        // In case there's an error (e.g., timeout or network issue), we still
        // call updateAnalyticsUserInfo without the googleClientId.
        console.error("Error retrieving GA clientID:", error);
        updateAnalyticsUserInfo(uuid, userInfo);
      });

    identify(uuid);
    cioIdentify(uuid);
  }, [currencyCode, geoData, geoError, isExperimentUser, swimlane, uuid]);
}

function getGAClientId(): Promise<string | null> {
  return new Promise((resolve, reject) => {
    GTag("get", project.googleAnalyticsTag, "client_id", (clientId) => {
      if (typeof clientId === "string" && clientId !== "") {
        resolve(clientId);
      } else {
        reject(null);
      }
    });
    setTimeout(() => reject(null), 100);
  });
}
