import { useEffect, useState } from "react";
import { trackUserBiEvent } from "../services/Api";
import Utils from "../utils";
import utils from "../utils";
import { logWarn } from "../utils/logs";
import { eventsData } from "./DispatcherGenerator";
import { validate as isValidUUID } from "uuid";
import { useSelector } from "react-redux";
import { selectAnalyticsUuid } from "../selectors/analytics";
import useRemoteConfig from "../custom-hooks/useRemoteConfig";

const ALL_EVENTS = [
  eventsData.EXTERNAL_USER_FLOW_STARTED,
  eventsData.EXTERNAL_LANDING_PAGE_VIEWED,
  eventsData.CLINIC_WEBFLOW_CTA_CLICKED,
  eventsData.INTAKE_FORM_COMPLETED,
  eventsData.COACH_SELECTED,
  eventsData.APPOINTMENT_BOOKED,
  eventsData.FOLLOW_UP_APPOINTMENT_CLICKED,
];

const storageIds = {
  TRACKING_PRODUCT: {
    key: "product_name",
    derived: false,
    events: ALL_EVENTS,
  },
  PARAM_SURVEY_PLATFORM: {
    key: "survey_platform",
    derived: true,
    events: [
      eventsData.EXTERNAL_LANDING_PAGE_VIEWED,
      eventsData.INTAKE_FORM_COMPLETED,
    ],
  },
  PARAM_SURVEY_VERSION_ID: {
    key: "survey_version_id",
    derived: true,
    events: [
      eventsData.EXTERNAL_LANDING_PAGE_VIEWED,
      eventsData.INTAKE_FORM_COMPLETED,
    ],
  },
  PARAM_APPOINTMENT_PLATFORM: {
    key: "appointment_platform",
    derived: true,
    events: [
      eventsData.APPOINTMENT_BOOKED,
      eventsData.INTAKE_FORM_COMPLETED,
      eventsData.FOLLOW_UP_APPOINTMENT_CLICKED,
    ],
  },
  PARAM_LANDING_PAGE_URL: {
    derived: true,
    key: "landing_page_url",
    events: [
      eventsData.EXTERNAL_LANDING_PAGE_VIEWED,
      eventsData.CLINIC_WEBFLOW_CTA_CLICKED,
    ],
  },
  PARAM_EXTERNAL_SOURCE: {
    key: "external_flow_source",
    derived: false,
    events: ALL_EVENTS,
  },
  PARAM_EXTERNAL_CAMPAIGN: {
    key: "external_flow_campaign",
    derived: false,
    events: ALL_EVENTS,
  },
  PARAM_EXTERNAL_STARTING_ELEMENT: {
    key: "external_flow_starting_element",
    derived: false,
    events: ALL_EVENTS,
  },
  PARAM_EMAIL: {
    key: "email",
    derived: false,
    events: ALL_EVENTS,
  },
  PARAM_WEBFLOW_CLICK_TYPE: {
    key: "webflow_click_type",
    derived: false,
    events: [eventsData.CLINIC_WEBFLOW_CTA_CLICKED],
  },
  PARAM_PHONE: {
    key: "phone",
    derived: false,
    events: [] as string[],
  },
};

function extractPlatformProperties(
  params: { [k: string]: string },
  generalSettings?: Record<string, string> | undefined,
): {
  [k: string]: string | null;
} {
  // HINT: platform might be determined by referrer too
  // typeform example: https://test.typeform.com/coaching-survey#content=xxxxx
  const url = params.to || "";
  let platformParams: { [k: string]: string | null } = { ...params };
  if (url.includes("lets.getinflow.io")) {
    window.localStorage.setItem(storageIds.PARAM_LANDING_PAGE_URL.key, url);
  } else if (url.includes("typeform")) {
    // survey_platform
    const surveyId = url
      .substring(url.lastIndexOf("/") + 1, url.length)
      .split("#")[0];
    window.localStorage.setItem(
      storageIds.PARAM_SURVEY_PLATFORM.key,
      "Typeform",
    );
    window.localStorage.setItem(
      storageIds.PARAM_SURVEY_VERSION_ID.key,
      surveyId,
    );
  } else if (url.includes("coach-intake")) {
    // survey_platform
    window.localStorage.setItem(
      storageIds.PARAM_SURVEY_PLATFORM.key,
      "Typeform",
    );
    window.localStorage.setItem(
      storageIds.PARAM_SURVEY_VERSION_ID.key,
      generalSettings?.TYPEFORM_PALTFORM ?? "",
    );
  } else if (url.includes("calendly") && !params.assigned_to) {
    window.localStorage.setItem(
      storageIds.PARAM_APPOINTMENT_PLATFORM.key,
      "Calendly",
    );
  } else if (
    url.includes("inflowcoaching") ||
    url.includes("squarespacescheduling")
  ) {
    window.localStorage.setItem(
      storageIds.PARAM_APPOINTMENT_PLATFORM.key,
      "Acuity",
    );
  } else if (url.includes("trysavvy")) {
    window.localStorage.setItem(storageIds.PARAM_SURVEY_PLATFORM.key, "Savvy");
  } else if (
    window.localStorage.getItem(storageIds.PARAM_APPOINTMENT_PLATFORM.key) ===
      "Calendly" &&
    params.assigned_to
  ) {
    platformParams = {};
    platformParams.to = params.to + "/invitees/" + params.invitee_uuid;
    platformParams.appointment_type_id = params.event_type_uuid;
    platformParams.appointment_name = params.event_type_name;
    platformParams.appointment_start_time = params.event_start_time;
    platformParams.appointment_end_time = params.event_end_time;
    platformParams.appointment_expert = params.assigned_to;
    platformParams.payment_amount = params.invitee_payment_amount;
    platformParams.appointment_cost_currency = params.invitee_payment_currency;
  } else if (
    window.localStorage.getItem(storageIds.PARAM_APPOINTMENT_PLATFORM.key) ===
      "Acuity" &&
    params.clientTime
  ) {
    // parse ClientTime and ClientDate properties
    delete platformParams.clientTime;
    delete platformParams.clientDate;
  }

  Object.values(storageIds).forEach((param) => {
    if (param.events.includes(params.event)) {
      platformParams[param.key] = window.localStorage.getItem(param.key);
    } else {
      delete platformParams[param.key];
    }
  });

  return platformParams;
}

async function trackEvent(
  uuid: string | null,
  event: string,
  params: { [k: string]: string | null },
  redirect: () => void,
) {
  if (uuid && isValidUUID(uuid)) {
    await trackUserBiEvent(uuid, event, params);
    redirect();
  } else if (uuid !== "{{customer.id}}") {
    const uuid = Utils.anonymousUuidStorage.get();
    if (uuid) {
      params.anonymous_distinct_id = "true";
      await trackUserBiEvent(uuid, event, params);
      redirect();
    } else {
      logWarn("Dispatcher: missing anonymous tracking UUID");
      redirect();
    }
  } else {
    logWarn("Dispatcher: bad tracking UUID");
    redirect();
  }
}

function Dispatcher() {
  const [error, setError] = useState("");
  const [eventData, setEventData] = useState({} as any);

  const { generalSettings } = useRemoteConfig();

  const analyticsUuid = useSelector(selectAnalyticsUuid);

  useEffect(() => {
    const paramsString = window.location.search;
    const searchParams = new URLSearchParams(paramsString);
    const paramsMap = Object.fromEntries(searchParams);
    if (!paramsMap.event || !(paramsMap.to || paramsMap.back)) {
      setError("Missing parameters");
      return;
    }
    if (paramsMap.token) {
      window.localStorage.setItem("token", utils.decodeToken(paramsMap.token));
    }
    if (paramsMap.email) {
      window.localStorage.setItem("email", paramsMap.email);
    }

    Object.values(storageIds).forEach((storageId) => {
      if (paramsMap[storageId.key] && !storageId.derived) {
        window.localStorage.setItem(storageId.key, paramsMap[storageId.key]);
      }
    });

    const params = extractPlatformProperties(paramsMap, generalSettings);
    params.redirect_url = params.to || paramsMap.to;
    delete params.to;
    delete params.uuid;
    delete params.test;
    delete params.event;
    delete params.back;
    delete params._gl; // gads
    if (analyticsUuid) {
      if (paramsMap.test) {
        setEventData({
          analyticsUuid,
          event: paramsMap.event,
          params,
        });
      } else {
        const redirect = () => {
          if (paramsMap.back) {
            window.history.back();
          } else {
            window.location.href = paramsMap.to;
          }
        };
        trackEvent(analyticsUuid, paramsMap.event, params, redirect);
      }
    }
  }, [error, generalSettings, analyticsUuid]);

  if (error) {
    return <div>{error}</div>;
  } else if (eventData.event) {
    const params = eventData.params;
    const paramViews: any[] = [];
    Object.keys(params).forEach((param, i) => {
      if (param === "redirect_url") {
        paramViews.push(
          <tr key={i}>
            <td style={styles.cell}>{param}</td>
            <td style={styles.cell}>
              <a
                href={eventData.params[param]}
                style={{ color: "blue" }}
                target="_blank"
                rel="noreferrer"
              >
                {eventData.params[param]}
              </a>
            </td>
          </tr>,
        );
      } else {
        paramViews.push(
          <tr key={i}>
            <td style={styles.cell}>{param}</td>
            <td style={styles.cell}>{eventData.params[param]}</td>
          </tr>,
        );
      }
    });
    const anonymousUuid = Utils.anonymousUuidStorage.get();
    return (
      <table style={{ borderWidth: 1 }}>
        <tbody>
          <tr>
            <th style={styles.cell}>Event</th>
            <th style={styles.cell}>{eventData.event}</th>
          </tr>
          <tr>
            <th style={styles.cell}>UUID</th>
            <th style={styles.cell}>{eventData.uuid}</th>
          </tr>
          <tr>
            <th style={styles.cell}>Anonymous UUID</th>
            <th style={styles.cell}>{anonymousUuid}</th>
          </tr>
          {paramViews}
        </tbody>
      </table>
    );
  } else {
    return <></>;
  }
}

const styles = {
  cell: {
    padding: 10,
    borderWidth: 1,
    borderColor: "black",
  },
};

export default Dispatcher;
