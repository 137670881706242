import type { Scores, useScores } from "../hooks/useScores.ts";

import { QuestionId } from "./QuizQuestion.ts";

export type QuizAction =
  | {
      type: "SINGLE_SELECT_ANSWER_SELECTED";
      questionId: QuestionId;
      answerId: string;
    }
  | {
      type: "MULTI_SELECT_ANSWER_SELECTED";
      questionId: QuestionId;
      answerId: string;
    }
  | {
      type: "MULTI_SELECT_ANSWER_DESELECTED";
      questionId: QuestionId;
      answerId: string;
    }
  | {
      type: "MULTI_SELECT_ANSWERS_CHANGED";
      questionId: QuestionId;
      answers: ReadonlyArray<string>;
    }
  | {
      type: "VALUE_ANSWER_CHANGED";
      questionId: QuestionId;
      value: string;
    }
  | {
      type: "DISCOVERED_INFLOW_CHANGED";
      discoveredInflow: string;
    }
  | {
      type: "DISCOVERED_INFLOW_OTHER_CHANGED";
      discoveredInflowOther: string;
    }
  | {
      type: "CLEAR_QUIZ_STATE";
    }
  | {
      type: "SCORES_CALCULATED";
      scores: ReturnType<typeof useScores>;
    };

export type MultipleChoiceAnswer = {
  type: "MULTIPLE_CHOICE";
  value: string | ReadonlyArray<string>;
};
export type ValueAnswer = { type: "VALUE"; value: string };

export type QuizAnswer = MultipleChoiceAnswer | ValueAnswer;

export type QuizState = {
  answers: Partial<Record<QuestionId, QuizAnswer>>;
  userInfo: Partial<{
    discoveredInflow: string;
    discoveredInflowOther: string;
  }>;
  scores: Partial<Scores>;
};

export function quizReducer(state: QuizState, action: QuizAction): QuizState {
  switch (action.type) {
    case "CLEAR_QUIZ_STATE": {
      console.log("clearing quiz state");
      return {
        ...state,
        answers: {},
        userInfo: {},
        scores: {},
      };
    }
    case "SINGLE_SELECT_ANSWER_SELECTED": {
      console.log("updating state answer with ", action);
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.questionId]: {
            type: "MULTIPLE_CHOICE",
            value: action.answerId,
          },
        },
      };
    }
    case "MULTI_SELECT_ANSWER_SELECTED": {
      const prevAnswers = state.answers[action.questionId];
      const newAnswers =
        prevAnswers == null ||
        prevAnswers.type === "VALUE" ||
        typeof prevAnswers.value === "string"
          ? [action.answerId]
          : [...prevAnswers.value, action.answerId];

      return {
        ...state,
        answers: {
          ...state.answers,
          [action.questionId]: {
            type: "MULTIPLE_CHOICE",
            value: newAnswers,
          },
        },
      };
    }
    case "MULTI_SELECT_ANSWER_DESELECTED": {
      const prevAnswers = state.answers[action.questionId];

      const newAnswers =
        prevAnswers == null || prevAnswers.type === "VALUE"
          ? []
          : typeof prevAnswers.value === "string"
            ? []
            : prevAnswers.value.filter((a) => a !== action.answerId);

      return {
        ...state,
        answers: {
          ...state.answers,
          [action.questionId]: {
            type: "MULTIPLE_CHOICE",
            value: newAnswers,
          },
        },
      };
    }
    case "MULTI_SELECT_ANSWERS_CHANGED": {
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.questionId]: {
            type: "MULTIPLE_CHOICE",
            value: action.answers,
          },
        },
      };
    }
    case "VALUE_ANSWER_CHANGED": {
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.questionId]: {
            type: "VALUE",
            value: action.value,
          },
        },
      };
    }
    case "DISCOVERED_INFLOW_CHANGED": {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          discoveredInflow: action.discoveredInflow,
        },
      };
    }
    case "DISCOVERED_INFLOW_OTHER_CHANGED": {
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          discoveredInflowOther: action.discoveredInflowOther,
        },
      };
    }
    case "SCORES_CALCULATED": {
      return {
        ...state,
        scores: action.scores,
      };
    }
  }
}
