import Loader from "react-loader-spinner";
import { usePageLoadContext } from "../../adhdQuiz/hooks/usePageLoadContext";
import { useEffect } from "react";

type LoadingRadialProps = {
  inline?: boolean;
  state?: string;
  color?: string;
  isInitial?: boolean;
};

function LoadingRadial({
  color,
  inline,
  isInitial,
  state,
}: LoadingRadialProps) {
  const { startTiming, stopTiming } = usePageLoadContext();

  useEffect(() => {
    if (state) {
      startTiming(state, isInitial);
      return () => stopTiming(state);
    }
  }, [state]);

  if (!inline) {
    return (
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <Loader
          type="Rings"
          color={color || "#000000"}
          height={80}
          width={80}
        />
      </div>
    );
  } else {
    return (
      <div className="inline-block">
        <Loader
          type="Rings"
          color={color || "#000000"}
          height={80}
          width={80}
        />
      </div>
    );
  }
}

export default LoadingRadial;
