type SwimlaneSplit = {
  name: string;
  split_pct: number;
};

export default function getSwimlane(swimlaneJson: string) {
  const storedSwimlane = localStorage.getItem("swimlane");
  if (storedSwimlane) {
    return storedSwimlane;
  }

  const swimlaneSplits: SwimlaneSplit[] = JSON.parse(swimlaneJson);

  const assignedSwimlane = assignToSwimlane(swimlaneSplits);
  localStorage.setItem("swimlane", assignedSwimlane);
  return assignedSwimlane;
}

function assignToSwimlane(swimlaneSplits: SwimlaneSplit[]): string {
  const random = Math.random() * 100;
  let sum = 0;
  for (const split of swimlaneSplits) {
    sum += split.split_pct;
    if (random < sum) {
      return split.name;
    }
  }
  return swimlaneSplits[swimlaneSplits.length - 1].name;
}
