const uiKitNeutral = {
  neutral800: "#2A2A2A",
  neutral700: "#454545",
  neutral600: "#6C6C6C",
  neutral500: "#9C9C9C",
  neutral400: "#C7C7CC",
  neutral300: "#EBEBEF",
  neutral200: "#F5F5F5",
  neutral100: "#FFFFFF",
};

const uiKitPrimary = {
  primary600: "#171C1D",
  primary500: "#043E4A",
  primary400: "#074F63",
  primary300: "#09697D",
  primary200: "#A7C4C9",
  primary100: "#E6F0F2",
};

const uiKitSecondary = {
  secondary500: "#7A3900",
  secondary400: "#A94D00",
  secondary300: "#F16E00",
  secondary200: "#F9C496",
  secondary100: "#FBEDD2",
};

const uiKitSurface = {
  surfacePrimary: uiKitNeutral.neutral100,
  surfaceSecondary: uiKitNeutral.neutral200,
};

const uiKitGeneric = {
  red: "#C53C3C",
  purple: "#6C3CD2",
  blue: "#2072AE",
  green: uiKitPrimary.primary300,
  yellow: "#F3CD43",
  orange: uiKitSecondary.secondary300,
};

const uiKitFunctional = {
  success: uiKitPrimary.primary300,
  warning: uiKitSecondary.secondary300,
  info: uiKitGeneric.blue,
  critical: uiKitGeneric.red,
};

const uiKitText = {
  textPrimary: uiKitNeutral.neutral800,
  textSubdued: uiKitNeutral.neutral600,
  textDisabled: uiKitNeutral.neutral500,
  textBrand: uiKitPrimary.primary300,
  textInvert: uiKitNeutral.neutral100,
  textCritical: uiKitFunctional.critical,
};

const uiKitBorder = {
  borderPrimary: uiKitNeutral.neutral300,
  borderSecondary: uiKitPrimary.primary300,
  borderDisabled: uiKitNeutral.neutral500,
  borderCritical: uiKitFunctional.critical,
};

const uiKitDecorative = {
  orange100: "#FEF7F4",
  orange102: "#FBE1D3",
  orange300: "#F6B595",
  green10: "#F6FAF9",
  teal500: "#598997",
};

const colors = {
  // https://www.figma.com/file/cwchKx63tymRH3IPn4ckDz/Inflow-UI-Kit
  uiKit: {
    ...uiKitBorder,
    ...uiKitDecorative,
    ...uiKitFunctional,
    ...uiKitGeneric,
    ...uiKitNeutral,
    ...uiKitPrimary,
    ...uiKitSecondary,
    ...uiKitSurface,
    ...uiKitText,
  },
  // https://www.figma.com/file/oWsc9N5PxxEChBAodCpaPr/Neuron-Web
  neuronWeb: {
    brandPrimaryLighter: "#EBF7F4",
    brandPrimaryLight: "#BEE3DB",
    brandPrimaryDark: "#89B0AE",
    brandSecondaryLight: "#FFF5EB",
    brandSecondaryDark: "#FFD6BA",
    brandSecondarySapphire: "#086375",
    neutralHaiti: "#242236",
    neutralEastBay: "#555B6E",
    neutralWhite: "#FFFFFF",
    neutralJaguar: "#21202B",
    neutralInvalid: "#E45249",
    backgroundGray1: "#8E8E93",
    backgroundGray2: "#AEAEB2",
    backgroundGray3: "#C7C7CC",
    backgroundGray4: "#E5E5EA",
    backgroundGray5: "#F2F2F7",
    actionOrange: "#F2994A",
  },
  // https://www.figma.com/file/CshyCGZNIgSNUrmHpnzfke/Marketing-Design-System
  marketingDesignSystem: {
    mintCream: "#EEF7F5",
    azure: "#e6f3f0",
    waterLeaf: "#bee3db",
    ziggurat: "#89b0ae",
    powderBlue: "#c4d8d7",
    peachDuff: "#FFD6BA",
    linen: "#FFF5EB",
    seashell: "#fff9f4",
    lightSand: "#FAF8F2",
  },
};

const spacing = {
  xxs: "0.125rem",
  xs: "0.25rem",
  sm: "0.5rem",
  smd: "0.75rem",
  md: "1rem",
  lg: "1.5rem",
  xl: "2rem",
  xxl: "2.5rem",
  xxxl: "4rem",
  xxxxl: "6rem",
  xxxxxl: "12rem",
};

const theme = {
  colors,
  spacing,
};

export default theme;
