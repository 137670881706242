import { AppAction, SET_ANALYTICS_UUID } from "../constants/actions";

import Utils from "../utils";
import anonymousUuidStorage from "../utils/anonymousUuidStorage";
import { logErr } from "../utils/logs";
import { v4 as uuidv4 } from "uuid";

export interface AnalyticsState {
  uuid: string;
}

const initialState: AnalyticsState = {
  uuid: initialAnalyticsUuid(),
};

export default (
  state: AnalyticsState = initialState,
  action: AppAction,
): AnalyticsState => {
  switch (action.type) {
    case SET_ANALYTICS_UUID: {
      checkUuidVal(action.data, "SET_ANALYTICS_UUID");
      return {
        ...state,
        uuid: action.data,
      };
    }
    default: {
      return state;
    }
  }
};

export function initialAnalyticsUuid(): string {
  // ORDER OF PRECEDENCE FOR ANALYTICS UUID
  // logged in token
  // url parameter "uuid" - from app (logged in user)
  // - store this if found
  // url parameter "userUuid" - from app
  // - store this if found
  // stored app non-anonymous uuid
  // stored anonymousUuid
  // generate new anonymousUuid

  const token = Utils.getToken();
  if (token != null && token !== "") {
    const uuid = Utils.getValFromToken(token, "uuid");
    if (uuid == null) {
      console.log("Failed to parse uuid from token");
    } else {
      checkUuidVal(uuid, "token");
      return uuid;
    }
  }

  const urlParams = new URLSearchParams(window.location.search);

  const queryUuid = urlParams.get("uuid");
  if (queryUuid != null && isValidUuidv4(queryUuid)) {
    window.localStorage.setItem("trackingVisitorUid", queryUuid);
    return queryUuid;
  }

  const queryAnonymousUuid = urlParams.get("userUuid");
  if (queryAnonymousUuid != null && isValidUuidv4(queryAnonymousUuid)) {
    anonymousUuidStorage.store(queryAnonymousUuid);
    return queryAnonymousUuid;
  }

  const storedUuid = window.localStorage.getItem("trackingVisitorUid");
  if (storedUuid) {
    checkUuidVal(storedUuid, "storedUuid");
    return storedUuid;
  }

  const storedAnonymousUuid = anonymousUuidStorage.get();
  if (storedAnonymousUuid) {
    checkUuidVal(storedAnonymousUuid, "storedAnonymousUuid");
    return storedAnonymousUuid;
  }

  const newAnonUUID = uuidv4();
  Utils.anonymousUuidStorage.store(newAnonUUID);
  return newAnonUUID;
}

function isValidUuidv4(uuid: string) {
  const uuidv4Regex =
    /^[\da-f]{8}-[\da-f]{4}-4[\da-f]{3}-[89ab][\da-f]{3}-[\da-f]{12}$/i;
  return uuidv4Regex.test(uuid);
}

export function checkUuidVal(val: any, source: string) {
  if (val === "undefined") {
    logErr(`Uuid string reads 'undefined' - ${source}`);
  } else if (`${val}` === "undefined") {
    logErr(`Uuid evaluates to undefined - ${source}`);
  }
}
