export interface NetworkInformation {
  connectionType: string;
  effectiveType: string;
  downlinkMax: number | null;
  downlink: number | null;
  rtt: number | null;
  saveData: boolean;
}

interface NavigatorConnection extends Navigator {
  connection?: {
    type?: string;
    effectiveType?: string;
    downlinkMax?: number;
    downlink?: number;
    rtt?: number;
    saveData?: boolean;
  };
  mozConnection?: {
    type?: string;
    effectiveType?: string;
    downlinkMax?: number;
    downlink?: number;
    rtt?: number;
    saveData?: boolean;
  };
  webkitConnection?: {
    type?: string;
    effectiveType?: string;
    downlinkMax?: number;
    downlink?: number;
    rtt?: number;
    saveData?: boolean;
  };
}

function getNetworkInformation(): NetworkInformation | null {
  const navigatorConnection = navigator as NavigatorConnection;
  const connection =
    navigatorConnection.connection ||
    navigatorConnection.mozConnection ||
    navigatorConnection.webkitConnection;

  if (!connection) {
    return null;
  }

  return {
    connectionType: connection.type || "unknown",
    effectiveType: connection.effectiveType || "unknown",
    downlinkMax: connection.downlinkMax || null,
    downlink: connection.downlinkMax || null,
    rtt: connection.rtt || null,
    saveData: connection.saveData || false,
  };
}

export default getNetworkInformation;
