import { ReactNode, useState, useEffect, useCallback } from "react";
import PageLoadContext from "../models/PageLoadContext";
import getNetworkInformation from "./network.helper";
import { trackAnalytics } from "../../utils/analytics";

interface Timing {
  start: number;
  end?: number;
}

interface TimingState {
  [key: string]: Timing;
}

const urlParams = new URLSearchParams(window.location.search);
const timeStamptAtTimeOfRedirect = parseInt(urlParams.get("ttfl") || "0");
const EVENT_TRIGGERED_KEY = "pageLoadEventTriggered";

const PageLoadProvider = ({ children }: { children: ReactNode }) => {
  const [timings, setTimings] = useState<TimingState>({});

  const startTiming = useCallback((state: string, inInitial?: boolean) => {
    if (!state) return;
    // If we have an initial redirect timestamp and start timestamp for the first loading state
    // We can use those to calculate the time taken for the initial redirect from Unbounce to the first loading spinner
    const now = Date.now();
    setTimings((prev) => ({
      ...prev,
      ...(inInitial &&
        timeStamptAtTimeOfRedirect && {
          "initial-redirect": {
            start: timeStamptAtTimeOfRedirect,
            end: now,
          },
        }),
      [state]: { start: now },
    }));
  }, []);

  const stopTiming = useCallback((state: string) => {
    if (!state) return;
    setTimings((prev) => ({
      ...prev,
      [state]: {
        ...prev[state],
        end: Date.now(),
      },
    }));
  }, []);

  const getTotalTime = useCallback((): number => {
    return Object.values(timings).reduce((total, timing) => {
      if (timing.end && timing.start) {
        return total + Math.round(timing.end - timing.start);
      }
      return total;
    }, 0);
  }, [timings]);

  const getBreakdown = useCallback((): Record<string, number> => {
    const breakdown: Record<string, number> = {};
    Object.keys(timings).forEach((key) => {
      const timing = timings[key];
      if (timing.end && timing.start) {
        breakdown[key] = Math.round(timing.end - timing.start);
      }
    });
    return breakdown;
  }, [timings]);

  const sendEvent = useCallback(() => {
    if (sessionStorage.getItem(EVENT_TRIGGERED_KEY)) {
      return;
    }

    const totalTimeTaken = getTotalTime();
    const breakdown = getBreakdown();

    trackAnalytics("InitialPageLoadTime", "pageLoad", {
      pageLoadTime: totalTimeTaken,
      breakdown,
      networkInfo: getNetworkInformation(),
    });

    sessionStorage.setItem(EVENT_TRIGGERED_KEY, "true");
  }, [getTotalTime, getBreakdown]);

  const handleUnload = useCallback(() => {
    if (!sessionStorage.getItem(EVENT_TRIGGERED_KEY)) {
      const totalTimeTaken = getTotalTime();
      const breakdown = getBreakdown();

      trackAnalytics("InitialPageLoadTimeFailed", "pageLoad", {
        pageLoadTime: totalTimeTaken,
        breakdown,
        networkInfo: getNetworkInformation(),
      });
    }
  }, [getTotalTime, getBreakdown]);

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload);
    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, [handleUnload]);

  return (
    <PageLoadContext.Provider
      value={{
        startTiming,
        stopTiming,
        sendEvent,
      }}
    >
      {children}
    </PageLoadContext.Provider>
  );
};

export default PageLoadProvider;
