const AsyncStorage = window.localStorage;

function store(anonymousUuid: string) {
  return AsyncStorage.setItem("anonymousUuid", anonymousUuid);
}

function get() {
  return AsyncStorage.getItem("anonymousUuid");
}

export default {
  store,
  get,
};
