import { useSelector } from "react-redux";
import { selectUserToken } from "../selectors/user";
import { useQuery } from "@tanstack/react-query";
import http from "../utils/http";
import { MedManagementStatus } from "../models/medManagement";

export default function useMedManagementStatus() {
  const token = useSelector(selectUserToken);

  return useQuery({
    queryKey: ["med-managment-status"],
    queryFn: () =>
      http(token)
        .get(`med-management/status`)
        .then((res) => res.data as MedManagementStatus),
    enabled: !!token,
  });
}
