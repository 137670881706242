import { ReactComponent as InflowLogoFull } from "../../icons/InflowLogoFull.svg";
import { LOGOUT_CLICKED } from "../../constants/actions";
import { NavLink } from "react-router-dom";
import { ReactElement } from "react";
import { useDispatch } from "react-redux";
import useMedManagementStatus from "../../custom-hooks/useMedManagementStatus";
import useUserData from "../../custom-hooks/useUserData";
import utils from "../../utils";

const SHOW_NAV_FOR = new Set(["clinic", "account"]);
const HIDE_NAV_FOR_PATHS = new Set(["/clinic/payment"]);

const NavBar = (): ReactElement => {
  const dispatch = useDispatch();
  const locationRoot = location.pathname.split("/")[1];

  const { data: medManagementStatus } = useMedManagementStatus();
  const { isLoggedIn, userData } = useUserData();

  const hasMemberEntitlement = userData?.hasMemberEntitlement;

  const canAccessMedManagement = medManagementStatus?.canAccessMedManagement;

  const showNavBar =
    !utils.isUserOnApp() &&
    locationRoot != null &&
    SHOW_NAV_FOR.has(locationRoot) &&
    !HIDE_NAV_FOR_PATHS.has(window.location.pathname);

  const getNavLinkClassName = ({ isActive }: { isActive: boolean }) => {
    return isActive
      ? "text-sapphireBlue font-bold border-b-2 border-sapphireBlue pb-2 mx-3"
      : "text-gray-500 pb-2 mx-3";
  };

  const handleLogOut = () => {
    dispatch({ type: LOGOUT_CLICKED });
    utils.logOut();
  };

  return showNavBar ? (
    <nav
      className={`mx-auto max-w-7xl flex h-20 px-5 justify-between items-center "flex"
      }`}
    >
      <InflowLogoFull className="w-28" />

      {canAccessMedManagement && (
        <div className="mr-auto ml-4 sm:ml-10 flex">
          <NavLink className={getNavLinkClassName} to="/clinic">
            Clinic
          </NavLink>
          {hasMemberEntitlement && (
            <NavLink to={"/account"} className={getNavLinkClassName}>
              Profile
            </NavLink>
          )}
        </div>
      )}

      {isLoggedIn && (
        <button className="text-sapphireBlue font-bold" onClick={handleLogOut}>
          Log out
        </button>
      )}
    </nav>
  ) : (
    <></>
  );
};

export default NavBar;
