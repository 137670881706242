import {
  AppAction,
  CHECKOUT_FULL_NAME_CHANGED,
  CHECKOUT_SELECTED_PRICE_CONFIRMED,
  CHECKOUT_SETUP_INTENT_CREATED,
  CHECKOUT_SUBSCRIPTION_CREATED,
  CLEAR_EXISTING_CUSTOMER_STRIPE_DETAILS,
  CLEAR_STRIPE_EMAIL_IN_USE,
  CLEAR_STRIPE_SUBSCRIPTION,
  STRIPE_CUSTOMER_EMAIL_IN_USE,
} from "../constants/actions";

import project from "../project";

export type CheckoutState = Partial<{
  selectedPriceId: string;
  fullName: string;
  stripeCustomerId: string;
  stripeClientSecret: string;
  stripeSubscriptionId: string;
  setupIntentId: string;
  paymentIntentId: string;
  errorMessage: string;
  emailInUseError?: string;
  needNewSubscription: boolean;
  currentOffering?: string;
}> & {
  emailInUseErrorCount: number;
};

const queryParams = new URLSearchParams(window.location.search);

function initialStripeState(): CheckoutState {
  return {
    stripeCustomerId: queryParams.get("stripeCustomerId") || undefined,
    stripeClientSecret:
      queryParams.get("setup_intent_client_secret") ||
      queryParams.get("payment_intent_client_secret") ||
      undefined,
    selectedPriceId: queryParams.get("selectedPriceId") || undefined,
    stripeSubscriptionId: queryParams.get("stripeSubscriptionId") || undefined,
    emailInUseErrorCount: 0,
    needNewSubscription: false,
    setupIntentId: queryParams.get("sid") || undefined,
    paymentIntentId: queryParams.get("pid") || undefined,
  };
}

// Initial state comes from the URL query params if redirected from Stripe payment
const initialState: CheckoutState = {
  ...initialStripeState(),
  emailInUseError: "",
  emailInUseErrorCount: 0,
};

function logNotProd(message: string) {
  if (project.customEnv !== "production") {
    console.log(message);
  }
}

export default (
  state: CheckoutState = initialState,
  action: AppAction,
): CheckoutState => {
  switch (action.type) {
    case CHECKOUT_SELECTED_PRICE_CONFIRMED: {
      return {
        ...state,
        selectedPriceId: action.priceId,
        currentOffering: action.offering,
      };
    }
    case CHECKOUT_FULL_NAME_CHANGED: {
      return {
        ...state,
        fullName: action.fullName,
      };
    }
    case CHECKOUT_SETUP_INTENT_CREATED: {
      logNotProd(CHECKOUT_SETUP_INTENT_CREATED);
      return {
        ...state,
        stripeClientSecret: action.stripeClientSecret,
        stripeCustomerId: action.stripeCustomerId,
        setupIntentId: action.setupIntentId,
        paymentIntentId: undefined,
        emailInUseError: "",
      };
    }
    case CHECKOUT_SUBSCRIPTION_CREATED: {
      logNotProd(CHECKOUT_SUBSCRIPTION_CREATED);
      return {
        ...state,
        stripeSubscriptionId: action.subscriptionId,
        stripeCustomerId: action.stripeCustomerId,
        stripeClientSecret: action.stripeClientSecret,
        setupIntentId: action.setupIntentId, // one or
        paymentIntentId: action.paymentIntentId, // the other
        emailInUseError: "",
        needNewSubscription: false,
      };
    }
    case CLEAR_STRIPE_SUBSCRIPTION: {
      logNotProd(CLEAR_STRIPE_SUBSCRIPTION);
      return {
        ...state,
        needNewSubscription: true,
      };
    }
    case CLEAR_EXISTING_CUSTOMER_STRIPE_DETAILS: {
      logNotProd(CLEAR_EXISTING_CUSTOMER_STRIPE_DETAILS);
      return {
        ...state,
        stripeSubscriptionId: "",
        stripeClientSecret: "",
        emailInUseError: "",
        setupIntentId: undefined,
        paymentIntentId: undefined,
      };
    }
    case STRIPE_CUSTOMER_EMAIL_IN_USE: {
      return {
        ...state,
        emailInUseError: action.code,
        emailInUseErrorCount: state.emailInUseErrorCount + 1,
      };
    }
    case CLEAR_STRIPE_EMAIL_IN_USE: {
      return {
        ...state,
        emailInUseError: "",
      };
    }
    default: {
      return state;
    }
  }
};
