import * as Sentry from "@sentry/react";
import project from "../project";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

export const initSentry = async (): Promise<typeof Sentry.ErrorBoundary> => {
  Sentry.init({
    dsn: project.sentryDSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    environment: project.customEnv,
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      project.tracePropagationTargetsRegex,
    ],
    // Session Replay
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  return Sentry.ErrorBoundary;
};

function createDump(additionalInfo: any): string {
  let res = "";
  try {
    res = JSON.stringify(additionalInfo);
  } catch (err) {
    res = additionalInfo?.toString() || "";
  }
  return res;
}

function createMetaData(additionalInfo: any) {
  if (typeof additionalInfo === "object") {
    return {
      dump: createDump(additionalInfo),
      ...additionalInfo,
    };
  }
  return {
    dump: createDump(additionalInfo),
  };
}

export function logTrace(message: string, additionalInfo?: any) {
  if (project.customEnv !== "development") {
    Sentry.addBreadcrumb({
      level: "info",
      message,
      data: createMetaData(additionalInfo),
      timestamp: Date.now(),
    });
  }

  if (project.customEnv === "development") {
    console.log(message, additionalInfo);
  }
}

/*
  Log an error
  Errors will be grouped by message, add instance information in additionalInfo.
*/
export function logErr(message: any | Error, additionalInfo?: any) {
  if (project.customEnv !== "development") {
    const error = typeof message === "string" ? new Error(message) : message;
    Sentry.captureException(error, {
      level: "error",
      extra: createMetaData(additionalInfo),
    });
  }
  if (project.customEnv === "development") {
    console.error(message, additionalInfo);
  }
}

/*
  Log a warning.
  Warnings will be grouped by message, add instance information in additionalInfo.
*/
export function logWarn(message: string | Error, additionalInfo?: any) {
  console.warn(message, additionalInfo);
  if (project.customEnv !== "development") {
    const error = typeof message === "string" ? new Error(message) : message;
    Sentry.captureException(error, {
      level: "warning",
      extra: createMetaData(additionalInfo),
    });
  }

  if (project.customEnv === "development") {
    console.warn(message, additionalInfo);
  }
}
