import { QuizState } from "../models/QuizState";

export function getPersistedQuizState(): QuizState {
  const previousQuizHistoryStr = localStorage.getItem("quizState");
  if (previousQuizHistoryStr == null) {
    return {
      answers: {},
      userInfo: {},
      scores: {},
    };
  } else {
    const parsed = JSON.parse(previousQuizHistoryStr);

    // Handle legacy case where we stored quiz state under the quiz ID
    if (parsed["fuba-v2"] != null) {
      return parsed["fuba-v2"];
    }

    return parsed;
  }
}

export function persistQuizState(quizState: QuizState) {
  localStorage.setItem("quizState", JSON.stringify(quizState));
}
