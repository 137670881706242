import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { useDispatch } from "react-redux";
import project from "./project";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as Sentry from "@sentry/react";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"],
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  attachReduxState: true,
});

const persistedReducer = persistReducer(persistConfig, rootReducer as any);

const store = configureStore({
  reducer: persistedReducer,
  devTools: project.customEnv !== "production",
  enhancers: (getDefaultEnhancers) => {
    return getDefaultEnhancers().concat(sentryReduxEnhancer);
  },
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
