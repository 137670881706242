import {
  ReportHandler,
  getCLS,
  getFCP,
  getFID,
  getLCP,
  getTTFB,
} from "web-vitals";

/**
 * If you want to start measuring performance in your app, pass a function to log results
 * (for example: reportWebVitals(console.log)) or send to an analytics endpoint.
 *
 * @see https://bit.ly/CRA-vitals
 */
export const reportWebVitals = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry) {
    getCLS(onPerfEntry);
    getFID(onPerfEntry);
    getFCP(onPerfEntry);
    getLCP(onPerfEntry);
    getTTFB(onPerfEntry);
  }
};
